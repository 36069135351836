import React, { useEffect, useRef, useState } from "react"
import s from "../assets/scss/headerTouchscreen.module.scss"

import wamLogoGray from "../assets/img/wam-logo-gray.svg"
import wamLogo from "../assets/img/wam-logo.svg"

import anaSozlesme from "../assets/pdf/ana-sozlesme.pdf"
import bagimsizDenetci from "../assets/pdf/bagimsiz-denetci-raporu.pdf"
import faaliyetRaporu from "../assets/pdf/faaliyet-raporu.pdf"
import yetkiBelgesi from "../assets/pdf/spk-faaliyet-yetki-belgesi.pdf"
import bagimsizDenetci2023yearEnd from "../assets/pdf/wam-portfoy-yonetimi-bagimsiz-denetim-raporu-2023-yil-sonu.pdf"
import faaliyetRaporu2023 from "../assets/pdf/wam-portfoy-yonetimi-faaliyet-raporu-2023-ara-donem.pdf"
import faaliyetRaporu2023yearEnd from "../assets/pdf/wam-portfoy-yonetimi-faaliyet-raporu-2023-yil-sonu.pdf"
import bagimsizDenetci2023firstHalf from "../assets/pdf/wam-portfoy-yonetimi-bagimsiz-denetim-raporu-2023-ara-donem.pdf"
import bagimsizDenetci2024firstHalf from "../assets/pdf/wam-portfoy-yonetimi-bagimsiz-denetim-raporu-2024-ara-donem.pdf"
import faaliyetRaporu2024firstHalf from "../assets/pdf/wam-portfoy-yonetimi-faaliyet-raporu-2024-ara-donem.pdf"

import cx from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next"
import usePreloaderStore from "../stores/preloaderStore"
import useHeaderStore from "../stores/themeStore"
import { gsap } from "../utils/gsap"
import Arrow from "./iconArrow"
import IconClose from "./iconClose"
import IconHamburger from "./iconHamburger"
import LanguageDropdown from "./languageDropdown"

export default function HeaderTouchscreen() {
  const touchHeaderRef = useRef(null)
  const q = gsap.utils.selector(touchHeaderRef)
  const hamburgerIconRef = useRef(null)
  const hamburgerMenuRef = useRef(null)
  const hamburgerSubmenuRef = useRef(null)
  const hamburgerSubmenu2Ref = useRef(null)
  const submenuTL = useRef(null)
  const submenu2TL = useRef(null)
  const subRefs = useRef([])
  const subRefs2 = useRef([])
  const [menuVisible, setMenuVisible] = useState(false)
  const isDarkTheme = useHeaderStore((state) => state.isDark)
  const [currentSubmenu, setCurrentSubmenu] = useState(null)
  const location = useLocation()
  const { t } = useTranslation()

  const navigation = {
    about: {
      sub: [
        {
          title: t("header.link.about.submenu.boardMembers"),
          url: t("routes.boardMembers"),
          pdf: null,
          inProgress: false,
        },
        {
          title: t("header.link.about.submenu.executives"),
          url: t("routes.executives"),
          pdf: null,
          inProgress: false,
        },
        {
          title: t("header.link.about.submenu.license"),
          url: null,
          pdf: yetkiBelgesi,
          inProgress: false,
        },
      ],
    },
    financialInfo: {
      sub: [
        {
          title: t("header.link.financialInfo.submenu.mainContract.title"),
          type: "MAIN_CONTRACT",
          pdf: anaSozlesme,
          url: null,
          inProgress: false,
        },
        {
          title: t("header.link.financialInfo.submenu.reports.title"),
          type: "REPORTS",
          url: null,
          pdf: null,
          inProgress: false,
          sub: [
            {
              title: t("header.link.financialInfo.submenu.reports.sub.s4.ui"),
              pdf: faaliyetRaporu2024firstHalf,
            },
            {
              title: t("header.link.financialInfo.submenu.reports.sub.s3.ui"),
              pdf: faaliyetRaporu2023yearEnd,
            },
            {
              title: t("header.link.financialInfo.submenu.reports.sub.s2.ui"),
              pdf: faaliyetRaporu2023,
            },
            {
              title: t("header.link.financialInfo.submenu.reports.sub.s1.ui"),
              pdf: faaliyetRaporu,
            },
          ],
        },
        {
          title: t("header.link.financialInfo.submenu.audit.title"),
          type: "AUDIT",
          pdf: null,
          url: null,
          inProgress: false,
          sub: [
            {
              title: t("header.link.financialInfo.submenu.audit.sub.s4.ui"),
              pdf: bagimsizDenetci2024firstHalf,
            },
            {
              title: t("header.link.financialInfo.submenu.audit.sub.s3.ui"),
              pdf: bagimsizDenetci2023yearEnd,
            },
            {
              title: t("header.link.financialInfo.submenu.audit.sub.s2.ui"),
              pdf: bagimsizDenetci2023firstHalf,
            },
            {
              title: t("header.link.financialInfo.submenu.audit.sub.s1.ui"),
              pdf: bagimsizDenetci,
            },
          ],
        },
      ],
    },
  }

  useEffect(() => {
    setMenuVisible(false)
  }, [location])

  const addToRefs = (el) => {
    if (el && !subRefs.current.includes(el)) {
      subRefs.current.push(el)
    }
  }

  const addToRefs2 = (el) => {
    if (el && !subRefs2.current.includes(el)) {
      subRefs2.current.push(el)
    }
  }

  useEffect(() => {
    gsap.set(q("[data-home-logo]"), {
      willChange: "transform",
      autoAlpha: 0,
    })

    submenuTL.current = gsap
      .timeline({ paused: true })
      .fromTo(
        [hamburgerSubmenuRef.current],
        {
          height: "0",
        },
        {
          height: "auto",
          duration: 0.5,
        },
        "start"
      )
      .fromTo(
        subRefs.current,
        {
          autoAlpha: 0,
          xPercent: -3,
        },
        {
          autoAlpha: 1,
          xPercent: 0,
          stagger: 0.03,
          delay: 0.1,
        },
        "start"
      )

    submenu2TL.current = gsap
      .timeline({ paused: true })
      .fromTo(
        [hamburgerSubmenu2Ref.current],
        {
          height: "0",
        },
        {
          height: "auto",
          duration: 0.5,
        },
        "start"
      )
      .fromTo(
        subRefs2.current,
        {
          autoAlpha: 0,
          xPercent: -3,
        },
        {
          autoAlpha: 1,
          xPercent: 0,
          stagger: 0.03,
          delay: 0.2,
        },
        "start"
      )
  }, [])

  useEffect(() => {
    if (menuVisible) {
      // document.body.style.overflow = "hidden";
      disableScrolling()

      gsap.to([hamburgerIconRef.current, hamburgerMenuRef.current], {
        autoAlpha: 1,
        pointerEvents: "auto",
      })
    } else {
      // document.body.style.overflow = "auto";
      enableScrolling()

      setSubmenu(false)
      setSubmenu2(false)
      setCurrentSubmenu(null)

      gsap.to([hamburgerMenuRef.current], {
        autoAlpha: 0,
        pointerEvents: "none",
      })
    }

    return () => {}
  }, [menuVisible])

  function disableScrolling() {
    var x = window.scrollX
    var y = window.scrollY
    window.onscroll = function () {
      window.scrollTo(x, y)
    }
  }

  function enableScrolling() {
    window.onscroll = function () {}
  }

  const isAnimationEnd = usePreloaderStore((state) => state.animationEnd)

  useEffect(() => {
    gsap.to(q("[data-home-logo]"), {
      autoAlpha: 1,
      delay: 2.5,
      duration: 1,
    })
  }, [isAnimationEnd])

  function handleCurrentSubmenu(item) {
    if (currentSubmenu === item.type) {
      return setCurrentSubmenu(null)
    }

    setCurrentSubmenu(item.type)
  }

  const [submenu, setSubmenu] = useState(false)
  const [submenu2, setSubmenu2] = useState(false)

  useEffect(() => {
    if (submenu) {
      submenuTL.current.play()
    } else {
      submenuTL.current.reverse()
    }
    return () => {}
  }, [submenu])

  useEffect(() => {
    if (submenu2) {
      submenu2TL.current.play()
    } else {
      submenu2TL.current.reverse()
    }
    return () => {}
  }, [submenu2])

  return (
    <header className={`${s.headerMobile} header-mobile}`} ref={touchHeaderRef}>
      <div className={s.imgWrapper}>
        <Link to={t("routes.home")}>
          <img data-home-logo className={s.wamLogo} src={isDarkTheme ? wamLogoGray : wamLogo} alt="WAM Logo" />
        </Link>
      </div>

      <div className={cx(s.hamburger, { [s.dark]: isDarkTheme })}>
        {menuVisible && (
          <div className={s.dWrapper}>
            <LanguageDropdown color="#ffffff" lg="true"></LanguageDropdown>
          </div>
        )}
        <AnimatePresence>
          <motion.div
            onClick={() => setMenuVisible((prev) => !prev)}
            key={menuVisible}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            ref={hamburgerIconRef}
            className={s.hamburgerIcon}
            style={{ width: menuVisible ? "3vw" : "5vw" }}
            alt="Hamburger Menu"
          >
            {menuVisible ? (
              <IconClose stroke={isDarkTheme ? "#727272" : "#ffffff"}></IconClose>
            ) : (
              <IconHamburger stroke={isDarkTheme ? "#727272" : "#ffffff"}></IconHamburger>
            )}
          </motion.div>
        </AnimatePresence>
      </div>

      <nav ref={hamburgerMenuRef} className={cx(s.hamburgerMenu, { [s.dark]: isDarkTheme })}>
        <ul className={s.menuItems}>
          <li className={s.menuItemWrapper}>
            <div
              className={s.menuItem}
              onClick={() => {
                setSubmenu((prev) => !prev)
                setSubmenu2(false)
              }}
            >
              <div
                className={cx(s.link, {
                  [s.withLine]: submenu,
                  [s.dark]: isDarkTheme,
                })}
              >
                <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{t("header.link.about.title")}</p>
                <div className={s.arrow}>
                  <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                </div>
              </div>
            </div>
            <ul className={`${s.hamburgerSubmenu} hamburger-submenu}`} ref={hamburgerSubmenuRef}>
              {navigation.about.sub.map((item, i) => (
                <li
                  className={cx(s.submenuItem, "submenu-item", {
                    [s.dark]: isDarkTheme,
                    [s.inProgress]: item.inProgress,
                  })}
                  key={i}
                >
                  {item.url ? (
                    <Link
                      to={item.url}
                      className={cx(s.subLink, s.withLine, {
                        [s.dark]: isDarkTheme,
                      })}
                      ref={(el) => addToRefs(el)}
                    >
                      <div className={s.arrow}>
                        <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                      </div>
                      <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{item.title}</p>
                    </Link>
                  ) : (
                    <a
                      target="_blank"
                      href={item.pdf}
                      rel="noreferrer"
                      className={cx(s.subLink, s.withLine, {
                        [s.dark]: isDarkTheme,
                      })}
                      ref={(el) => addToRefs(el)}
                    >
                      <div className={s.arrow}>
                        <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                      </div>
                      <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{item.title}</p>
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </li>

          <li className={s.menuItemWrapper}>
            <div
              className={s.menuItem}
              onClick={() => {
                setSubmenu(false)
                setSubmenu2((prev) => !prev)
              }}
            >
              <div
                className={cx(s.link, {
                  [s.withLine]: submenu2,
                  [s.dark]: isDarkTheme,
                })}
              >
                <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{t("header.link.financialInfo.title")}</p>
                <div className={s.arrow}>
                  <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                </div>
              </div>
            </div>
            <ul className={s.hamburgerSubmenu} ref={hamburgerSubmenu2Ref}>
              {navigation.financialInfo.sub.map((item, i) => (
                <React.Fragment key={i}>
                  <li
                    className={cx(s.submenuItem, "submenu-item", {
                      [s.dark]: isDarkTheme,
                      [s.inProgress]: item.inProgress,
                    })}
                  >
                    {(() => {
                      if (item.url) {
                        return (
                          <Link
                            to={item.url}
                            className={cx(s.subLink, s.withLine, {
                              [s.dark]: isDarkTheme,
                            })}
                            ref={(el) => addToRefs2(el)}
                          >
                            <div className={s.arrow}>
                              <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                            </div>
                            <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{item.title}</p>
                          </Link>
                        )
                      } else if (item.pdf) {
                        return (
                          <a
                            className={cx(s.subLink, s.withLine, {
                              [s.dark]: isDarkTheme,
                            })}
                            href={item.pdf}
                            ref={(el) => addToRefs2(el)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className={s.arrow}>
                              <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                            </div>
                            <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{item.title}</p>
                          </a>
                        )
                      } else if (item.sub?.length) {
                        return (
                          <div
                            className={cx(s.subLink, s.withLine, {
                              [s.dark]: isDarkTheme,
                            })}
                            ref={(el) => addToRefs2(el)}
                            onClick={() => handleCurrentSubmenu(item)}
                          >
                            <div className={s.arrow}>
                              <Arrow color={isDarkTheme ? "#003566" : "#ffffff"} />
                            </div>
                            <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{item.title}</p>
                          </div>
                        )
                      }
                    })()}
                  </li>

                  <div
                    className={s.innerItems}
                    style={{
                      maxHeight: `${item.type === currentSubmenu ? "450px" : "0px"}`,
                      opacity: `${item.type === currentSubmenu ? "1" : "0"}`,
                      transition: "all 600ms ease",
                    }}
                  >
                    {item.sub?.length &&
                      item.sub.map((item, i) => (
                        <li
                          className={cx(s.submenuItemInner, "submenu-item", {
                            [s.dark]: isDarkTheme,
                            [s.inProgress]: item.inProgress,
                          })}
                          key={i}
                        >
                          <a
                            target="_blank"
                            href={item.pdf}
                            rel="noreferrer"
                            className={cx(s.subLink, {
                              [s.dark]: isDarkTheme,
                            })}
                            ref={(el) => addToRefs2(el)}
                          >
                            <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{item.title}</p>
                          </a>
                        </li>
                      ))}
                  </div>
                </React.Fragment>
              ))}
            </ul>
          </li>

          <li className={s.menuItem}>
            <Link to={t("routes.contact")} className={s.link}>
              <p className={cx(s.text, { [s.dark]: isDarkTheme })}>{t("header.link.contact")}</p>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}
